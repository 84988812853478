<template>
  <div>
    <LoadingOverlay v-if="!getItems.length" />
    <div class="facility-list" v-else>
      <CRow class="row mb-3">
        <div class="facility-count bg-primary col-lg-2 col-md-6 col-sm-12">
          <div class="mr-2" name="total-count">
            Total Facilities
            <span>{{totalCount && totalCount.toLocaleString()}}</span>
          </div>
        </div>
      </CRow>
      <h1>Registered Facilities</h1>
      <CDataTable
        :striped="striped"
        :bordered="bordered"
        :small="small"
        :items="getItems"
        :fields="fields"
        :items-per-page="small ? limit : 5"
        sorter
        :tableFilter="{label : 'Search',placeholder :'Type for search'}"
        pagination
      >
        <template #action="{item, index}">
          <td class="py-2">
            <CButton
              color="primary"
              square
              size="sm"
              class="mr-1"
              @click="openEditModel(item, index)"
            >Edit</CButton>
            <CButton
              color="primary"
              square
              variant="outline"
              size="sm"
              class="mr-1"
              @click="openConfigModel(item, index)"
              v-c-tooltip="'Config'"
            >
              <CIcon name="cil-settings" />
            </CButton>
            <CButton
              color="primary"
              square
              variant="outline"
              size="sm"
              class="mr-1"
              @click="openUserModel(item, index)"
              v-c-tooltip="'User Report'"
            >
              <CIcon name="cil-user" />
            </CButton>
          </td>
        </template>
        <template #download="{item, index}">
          <td class="py-2 d-flex justify-content-center">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              class="hand-icon action-button"
              @click="candidatesDownload(item, index)"
              v-c-tooltip="'Candidates Download'"
            >
              <i class="fas fa-download"></i>
            </CButton>
          </td>
        </template>

      </CDataTable>
    </div>
    <div v-if="isModalOpen">
      <facilityConfig :facilityData="facilityData" @closeModal="closeModal" v-on="$listeners" />
    </div>
    <div v-if="isContactModalOpen">
      <facilityContact :facility="facilityData" @closeModal="closeModal" v-on="$listeners" />
    </div>
    <div v-if="isEditModalOpen">
      <facilityEdit :facility="facilityData" @closeModal="closeModal" v-on="$listeners" />
    </div>
    <div v-if="isUserModalOpen">
      <facilityUserReport :facility="facilityData" @closeModal="closeModal" v-on="$listeners" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import facilityConfig from "@/containers/Facility/FacilityConfig";
import facilityEdit from "@/containers/Facility/FacilityEdit";
import facilityUserReport from "@/containers/Facility/FacilityUserReport";

export default {
  name: "FacilityList",
  components: {
    facilityConfig,
    facilityEdit,
    facilityUserReport
  },
  props: {
    fields: {
      type: Array,
      default() {
        return [
          { key: "facility_name", label: "Facility Name", _style: "width:20%" },
          { key: "category", label: "Category", _style: "width:8%" },
          { key: "group", label: "Group", _style: "width:5%" },
          { key: "location", label: "Location", _style: "width:5%" },
          { key: "type", label: "Type", _style: "width:5%" },
          { key: "main_contact", label: "Main Contact", _style: "width:15%" },
          { key: "registered", label: "Registered", _style: "width:10%" },
          { key: "last_login", label: "Last Login", _style: "width:10%" },
          {
            key: "action",
            label: "Action",
            sorter: false,
            filter: false,
            _style: "width:15%"
          },
          {
            key: "download",
            label: "Candidates Download",
            sorter: false,
            filter: false,
            _style: "width:5%"
          }

        ];
      }
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      small: true,
      striped: true,
      bordered: true,
      sorter: { external: true, resetable: true },
      isModalOpen: false,
      facilityData: {},
      isContactModalOpen: false,
      isEditModalOpen: false,
      isUserModalOpen: false
    };
  },
  computed: {
    ...mapGetters(["getFacilityList", "getCustomerId"]),
    getItems() {
      return this.getFacilityList
        .map(data => {
          const container = {};
          container.facility_id = data.main_organisation_id;
          container.customer_uid = data.customer_uid;
          container.facility_name = data.name;
          container.location = this.getLocation(data.main_contact);
          container.type = this.facilityType(data.government, data.private);
          container.group = this.getGroup(data.main_organisation);
          container.category = this.categoryType(data.main_organisation);
          container.main_contact = this.fullName(data.main_contact);
          container.registered = this.registedDate(data.main_user);
          container.last_login = this.lastLoginDate(data.main_user);
          return container;
        })
        .reverse();
    },
    totalCount() {
      return this.getFacilityList.length;
    }
  },
  mounted() {
    this.fetchFacilityList();
  },
  methods: {
    ...mapActions(["fetchFacilityList", "downloadCandidates"]),
    facilityType(isGovernment, isPrivate) {
      return isGovernment && isPrivate
        ? "Both"
        : isGovernment
        ? "Government"
        : "Private";
    },
    getGroup(data) {
      if (data != null)
        return data.group != null ? (data.group ? "Yes" : "No") : "--";
      return "--";
    },
    getLocation(data) {
      if (data != null)
        return data.location.location != null ? data.location.location : "--";
      return "--";
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    registedDate(data) {
      if (data != null) {
        if (data.first_login_date != null)
          return moment(data.first_login_date).format("Do MMM YYYY");
      }
      return "--";
    },
    lastLoginDate(data) {
      if (data != null) {
        if (data.last_login_date)
          return moment(data.last_login_date).format("Do MMM YYYY");
      }
      return "--";
    },
    categoryType(data) {
      if (data != null) {
        if (data.organisation_type != null) {
          return data.organisation_type.organisation_type;
        }
      }
      return "--";
    },
    fullName(data) {
      if (data != null) return data.name != null ? data.name : "--";
      return "--";
    },
    openConfigModel(item, index) {
      this.facilityData = item;
      this.isModalOpen = true;
    },
    openEditModel(item, index) {
      this.facilityData = item;
      this.isEditModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.isContactModalOpen = false;
      this.isEditModalOpen = false;
      this.isUserModalOpen = false;
      this.facilityData = {};
    },
    candidatesDownload(item, index) {
      let customer_id = this.getFacilityList[
        this.getFacilityList.findIndex(
          e => e.main_organisation_id === item.facility_id
        )
      ].customer_uid;
      let facility_name = item.facility_name;
      this.downloadCandidates({ customer_id, facility_name });
    },
    openUserModel(item, index){
      this.facilityData = item;
      this.isUserModalOpen=true;
    }
  }
};
</script>

<style lang="scss" scoped>
.facility-list {
  margin-bottom: 5rem;
}
.facility-count {
  div {
    color: $white;
    padding: 3px;
    text-align: center;
    span {
      display: block;
      padding: 3px;
      background-color: $white;
      font-weight: 700;
      color: $color-black;
    }
  }
}
</style>