<template>
  <div class="stepper">
    <div class="top">
      <div
        v-for="(item, index) in options.headers"
        :key="index"
        :class="{'step-header': true, 'active': index <= currentPosition, 'disabled': item.disabled, 'start': index === 0}">
        <div class="header-indicator">
          <div class="step-header-line" v-if="index > 0"></div>
          <div class="step-header-content" @click="slideTo(index)" v-c-tooltip="{ content: item.tooltipTitle,
          placement: item.tooltipPlacement }" variant='primary'>{{index+1}}</div>
        </div>
        <div class="title" :class="{'title': true}">{{item.title}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Stepper",
  props: ["options", "goTo", "activeBlock"],
  data() {
    return {
      currentPosition: this.activeBlock,
    };
  },
  watch: {
    activeBlock(val) {
      this.slideTo(val);
    }
  },
  methods: {
    slideTo(index) {
      this.goTo(index,this);
      if (this.currentPosition === index) return;
        this.currentPosition = index;
    }
  }
};
</script>
<style lang="scss" scoped>
 .tooltip-inner{
  color:#FFF !important;
}


</style>