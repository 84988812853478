<template>
  <div>
    <CModal
      class="text-black popupfnt imageModel"
      :show.sync="darkModal"
      :close-on-backdrop="false "
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Facility Edit</h6>
        <CButtonClose @click="closeModal" class="text-black" />
      </template>
      <template #footer>
        <CButton type="button" color="primary" class="px-4" @click="gotoStep(1)" v-if="block === 0">NEXT</CButton>
        <CButton type="button" color="secondary" class="px-4" @click="gotoStep(0)" v-if="block === 1">Back</CButton>
        <CButton type="button" color="primary" class="px-4" @click="onSubmit" v-if="block===1">Update</CButton>
      </template>
      <LoadingOverlay v-if="isFetching" />
      <div v-else>
        <div class="w-100 d-flex justify-content-start sticky-header">
          <Stepper :options="stepperOptions" :activeBlock="block" :goTo="slideTo"></Stepper>
        </div>
        <ValidationObserver ref="editFacilityDetail" v-slot="{ handleSubmit }">
          <form id="editFacilityDetail" @submit.prevent="handleSubmit(onSubmit)">
            <div v-show="block===0">
              <h5 class="pb-1 px-1 mb-0">Facility Details</h5>
              <CCardGroup class="has-loading-overlay">
                <CCard>
                  <CCardBody>
                    <CRow class="row mb-2">
                      <label class="required col-lg-3 col-md-12">Facility Name</label>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <ValidationProvider
                          :rules="{ required: true, facility_name: 100 }"
                          v-slot="{errors}"
                        >
                          <TextInput
                            name="facility_name"
                            :value="facilities.facility_name"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                    <CRow class="row mb-2">
                      <label class="required col-lg-3 col-md-12">Category</label>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <Select
                            name="organisation_type_id"
                            :value="facilities.organisation_type_id"
                            @input="handleChangeSelect"
                            :options="options && options['category'] ? options['category'] : []"
                            :taggable="false"
                            :multiple="false"
                            :clearable="true"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                    <CRow class="row mb-2">
                      <label class="required col-lg-3 col-md-12">Group</label>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <RadioButton
                            name="group"
                            :value="facilities.group"
                            :options="options && options['group'] ? options['group'] : []"
                            @change="handleChangeRadio"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                    <CRow class="row mb-2">
                      <label class="col-lg-3 col-md-12">Location</label>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                          <Select
                            name="location_id"
                            :value="facilities.location_id"
                            @input="handleChangeSelect"
                            :options="options && options['locations'] ? options['locations'] : []"
                            :taggable="false"
                            :multiple="false"
                            :clearable="true"
                          />
                      </div>
                    </CRow>
                    <CRow class="row mb-2">
                      <label class="col-lg-3 col-md-12">Facility Address</label>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                          <TextInput
                            name="address"
                            :value="facilities.address"
                            @input="handleInput"
                          />
                      </div>
                    </CRow>
                    <CRow class="row mb-2">
                      <label class="required col-lg-3 col-md-12">Type</label>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <RadioButton
                            name="type"
                            :value="facilities.type"
                            :options="options && options['type'] ? options['type'] : []"
                            @change="handleChangeRadio"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                    <CRow class="row mb-2">
                      <label class="col-lg-3 col-md-12">Website</label>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <ValidationProvider
                          :rules="{ website_rule: /^(http\:\/\/|https\:\/\/)?([a-z0-9][a-z0-9\-]*\.)+[a-z0-9][a-z0-9\-]*$/ }"
                          v-slot="{errors}"
                        >
                          <TextInput
                            name="website"
                            :value="facilities.website"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                    <CRow class="row mb-2">
                      <label class="col-lg-3 col-md-12">Phone</label>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                          <PhoneInput
                            name="phone"
                            :value="facilities.phone_no"
                            :options="options && options['dialCode'] ? options['dialCode'] : []"
                            label="dialing_code"
                            @change="handleInput"
                          />
                      </div>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </div>
            <div v-show="block===1">
              <h5 class="pb-2 px-1 mb-0 underline">Main Contact</h5>
              <CCardGroup class="has-loading-overlay">
                <CCard>
                  <CCardBody class="pb-2">
                    <CRow class="row mb-2">
                      <label class="col-lg-3 col-md-12">Title</label>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                          <Select
                            name="title_id"
                            :value="facilities.title_id"
                            @input="handleChangeSelect"
                            :options="options && options['titles'] ? options['titles'] : []"
                            :taggable="false"
                            :multiple="false"
                            :clearable="true"
                          />
                      </div>
                    </CRow>
                    <CRow class="row mb-2">
                      <label class="required col-lg-3 col-md-12">First Name</label>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <TextInput
                            name="first_name"
                            :value="facilities.first_name"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                    <CRow class="row mb-2">
                      <label class="required col-lg-3 col-md-12">Family Name</label>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <TextInput
                            name="last_name"
                            :value="facilities.last_name"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                    <CRow class="row mb-2">
                      <label class="required col-lg-3 col-md-12">Email</label>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <ValidationProvider rules="required|email" v-slot="{errors}">
                          <TextInput
                            name="email"
                            :value="facilities.email"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                    <CRow class="row mb-2">
                      <label class="col-lg-3 col-md-12">Phone</label>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                          <PhoneInput
                            name="contact_phone_no"
                            :value="facilities.contact_phone_no"
                            :options="options && options['dialCode'] ? options['dialCode'] : []"
                            label="dialing_code"
                            @change="handleInput"
                          />
                      </div>
                    </CRow>
                    <CRow class="row mb-2">
                      <label class="col-lg-3 col-md-12">Mobile</label>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                          <PhoneInput
                            name="alt_phone_no"
                            :value="facilities.alt_phone_no"
                            :options="options && options['dialCode'] ? options['dialCode'] : []"
                            label="dialing_code"
                            @change="handleInput"
                          />
                      </div>
                    </CRow>
                    <CRow class="row mb-2">
                      <label class="col-lg-3 col-md-12">User Name</label>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <TextInput
                          name="username"
                          :value="facilities.username"
                          @input="handleInput"
                          :disabled="true"
                        />
                      </div>
                      <div class="col-lg-3 col-md-6 col-sm-12">
                 <CButton
                    color="primary"
                    class="mb-1 fz-1"
                    @click="passwordReset()"
                  >Reset Password</CButton>
              </div>
                    </CRow>  
                    <CRow class="border-top">
                      <a
                        class="btn btn-lg toggle pt-1 pb-1 px-1 mb-0"
                        @click="isAdvancedVisible = !isAdvancedVisible;"
                      >
                        <span class="ml-2 mr-2">Additional Information</span>
                        <CIcon v-if="!isAdvancedVisible" size="lg" name="cil-plus" />
                        <CIcon v-else size="lg" name="cil-minus" />
                      </a>
                    </CRow>
                    <div v-show="isAdvancedVisible">
                      <CRow class="row mb-2">
                        <label class="col-lg-3 col-md-12">No. of Beds</label>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <TextInput
                            name="num_beds"
                            type="number"
                            :value="facilities.num_beds"
                            @input="handleInput"
                          />
                        </div>
                      </CRow>
                      <CRow class="row mb-2">
                        <label class="col-lg-3 col-md-12">Facility License No</label>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <TextInput
                            name="facility_license_no"
                            :value="facilities.facility_license_no"
                            @input="handleInput"
                          />
                        </div>
                      </CRow>
                      <CRow class="row mb-2">
                        <label class="col-lg-3 col-md-12">Additional Info</label>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <TextInput
                            name="additional_info"
                            :value="facilities.additional_info"
                            @input="handleInput"
                          />
                        </div>
                      </CRow>
                    </div>               
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>


<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { times } from "lodash";
import Stepper from "@/components/reusable/bars/stepper.vue";
import { extend } from "vee-validate";
import { required, email, confirmed, min, max } from "vee-validate/dist/rules";
import { regex } from "vee-validate/dist/rules";
import { deepClone } from "@/helpers/helper.js";

import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";

extend("required", { ...required, message: "This field is required" });
extend("facility_name", { ...max, message: "Max length: 100 character" });
extend("email", { ...email, message: "Invalid email" });
extend("website_rule", { ...regex, message: "Invaild Website" });
extend("password_length", {
  ...min,
  message: "Password be atleast 8 characters"
});

export default {
  components: {
    Select,
    TextInput,
    RadioButton,
    PhoneInput,
    Stepper
  },
  props: ["facility"],
  data() {
    return {
      isAdvancedVisible: false,
      block: 0,
      facilities:{},
      payload: {},
      darkModal: false,
      stepperOptions: {
        headers: [
          {
            title: "",
            tooltipTitle: "Facility Details",
            tooltipPlacement: "bottom-start"
          },
          {
            title: "",
            tooltipTitle: "Main Contact Details",
            tooltipPlacement: "bottom-start"
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters([
      "locations",
      "titles",
      "getOrganisationTypeList",
      "locationCountryInfo",
      "getCustomerDetails",
      "dialingCodeWithCountryCode",
    ]),
    isFetching() {
      if (Object.keys(this.getCustomerDetails).length) {
        const facilityData = this.getCustomerDetails;
        this.facilities={
          facility_name: facilityData.name,
          organisation_type_id: {
            code:
              facilityData.main_organisation.organisation_type
                .organisation_type_id,
            label:
              facilityData.main_organisation.organisation_type.organisation_type
          },
          group: facilityData.main_organisation.group,
          location_id: {
            code: facilityData.main_contact.location_id,
            label: facilityData.main_contact.location.location
          },
          address: facilityData.main_organisation.address,
          country_id: facilityData.main_organisation.country_id,
          type: this.facilityType(
            facilityData.government,
            facilityData.private
          ),
          website: facilityData.main_organisation.website,
          phone_no: facilityData.main_organisation.phone_no,
          title_id: facilityData.main_contact.title
            ? {
                code: facilityData.main_contact.title.title_id,
                label: facilityData.main_contact.title.title
              }
            : null,
          first_name: facilityData.main_user.first_name,
          last_name: facilityData.main_user.surname,
          email: facilityData.main_contact.email,
          contact_phone_no: facilityData.main_contact.phone_no,
          alt_phone_no: facilityData.main_contact.alt_phone_no,
          username: facilityData.main_user.user_name,
          num_beds:
            facilityData.main_organisation.num_beds > 0
              ? facilityData.main_organisation.num_beds
              : null,
          facility_license_no:
            facilityData.main_organisation.facility_license_no,
          additional_info: facilityData.main_organisation.additional_info
        };
        return false;
      }
      return true;
    },
    options() {
      return {
        locations: this.locations || [],
        type: [
          { id: 1, label: "Goverment" },
          { id: 2, label: "Private" },
          { id: 3, label: "Both" }
        ],
        group: [
          { id: true, label: "Yes" },
          { id: false, label: "No" }
        ],
        category: this.getOrganisationTypeList || [],
        titles: this.titles || [],
        dialCode: this.dialingCodeWithCountryCode || []
      };
    }
  },
  methods: {
    ...mapActions([
      "initFetchFacilityEditDetails",
      "updateFacilityDetails",
      "showToast",
      "resetPassword"
    ]),
    handleInput(name, value) {
      Vue.set(this.facilities, name, value);
      Vue.set(this.payload, name, value);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.facilities, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null
      };
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value.id === false || value.code === false) {
        val = false;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.facilities, name, value);
      if (name === "type") {
        this.payload = {
          ...this.payload,
          government: val === 1 || val === 3,
          private: val === 2 || val === 3
        };
      } else {
        this.payload = {
          ...this.payload,
          [name]: val
        };
      }
    },
    facilityType(isGovernment, isPrivate) {
      if (isGovernment && isPrivate) return 3;
      return isGovernment ? 1 : 2;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    gotoStep(step) {
      if (this.pageValidate()) {
        this.block = step;
        this.$store.dispatch("scrollTop");
      } else {
        this.block = 0;
        this.showPageToaster();
      }
    },
    slideTo(event, obj) {
      if (this.pageValidate()) {
        this.block = event;
      } else {
        this.block = 0;
        this.showPageToaster();
      }
    },
    pageValidate() {
      if (this.block === 0) {
        return (
          this.facilities.facility_name &&
          this.facilities.facility_name.length &&
          this.facilities.organisation_type_id.code &&
          this.facilities.group != null &&
          this.facilities.type
        );
      }
      return true;
    },
    async onSubmit() {
      const isValid = await this.$refs.editFacilityDetail.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!"
        });
        return;
      }
      this.payload = {
        ...this.payload,
        facility_name: this.facilities.facility_name,
        organisation_type_id: this.facilities.organisation_type_id.code,
        location_id: this.facilities.location_id.code,
        government: this.facilities.type === 1 || this.facilities.type === 3,
        private: this.facilities.type === 2 || this.facilities.type === 3,
        group: this.facilities.group,
        address: this.facilities.address ,
        country_id: this.facilities.location_id?this.facilities.location_id.code:null,
        website: this.facilities.website,
        phone_no: this.facilities.phone_no ,
        num_beds: this.facilities.num_beds ? this.facilities.num_beds : 0,
        facility_license_no: this.facilities.facility_license_no,
        additional_info: this.facilities.additional_info,
        title_id: this.facilities.title_id?this.facilities.title_id.code:null,
        first_name: this.facilities.first_name,
        last_name: this.facilities.last_name,
        email: this.facilities.email,
        contact_phone_no: this.facilities.contact_phone_no ,
        alt_phone_no: this.facilities.alt_phone_no 
      };
      this.handleFacilityUpdate(this.payload);
    },
    handleFacilityUpdate(data) {
      const customer_uid = this.facility.customer_uid;
      this.updateFacilityDetails({ data, customer_uid });
      this.closeModal();
    },
    showPageToaster() {
      this.showToast({
        class: "bg-danger text-white",
        message: "Please fill mandatory fields!",
        autohide: 3000
      });
    },
    passwordReset(){
      this.resetPassword(this.facilities.username);
    }
  },
  mounted() {
    this.darkModal = true;
    this.initFetchFacilityEditDetails(this.facility.customer_uid);
  }
};
</script>
<style lang="scss" scoped>
.fz-1{
  font-size: 0.8rem;
}
</style>

