<template>
  <div>
    <CModal :show.sync="darkModal" :close-on-backdrop="false " color="primary" size="lg">
      <template #header>
        <h6 class="modal-title">Facility Admin / User Report</h6>
        <CButtonClose @click="closeModal" class="text-black primary" />
      </template>
      <template #footer>
        <CButton type="button" color="primary" class="px-4" @click="closeModal">OK</CButton>
      </template>
      <LoadingOverlay v-if="!facilityUsers.length" />
      <div v-else>
        <h6>{{facility.facility_name}}</h6>
        <CDataTable
          :striped="striped"
          :bordered="bordered"
          :small="small"
          :items="facilityUsers"
          :fields="fields"
          :items-per-page="small ? limit : 5"
          pagination
        ></CDataTable>
      </div>
    </CModal>
  </div>
</template>


<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  props: ["facility"],
  data() {
    return {
      darkModal: false,
      small: true,
      striped: true,
      bordered: true,
      fields: [
        { key: "first_name", label: "First Name" },
        { key: "last_name", label: "Last Name" },
        { key: "full_name", label: "Full Name" },
        { key: "email", label: "Email" },
        { key: "role", label: "Role" },
        { key: "last_login", label: "Last Login" }
      ],
      limit: 10
    };
  },
  computed: {
    ...mapGetters(["getFacilityUsers"]),
    facilityUsers() {
      return this.getFacilityUsers.map(data => {
        const facilityData = {};
        facilityData.first_name = this.toTitleCase(data.first_name);
        facilityData.last_name = this.toTitleCase(data.surname);
        facilityData.full_name =
          this.toTitleCase(data.first_name) +
          " " +
          this.toTitleCase(data.surname);
        facilityData.email = data.email;
        facilityData.role = this.getRole(data.roles);
        facilityData.last_login = this.getLastLogin(data.last_login_date);
        return facilityData;
      });
    }
  },
  methods: {
    ...mapActions(["fetchFacilityUserReport"]),
    closeModal() {
      this.$emit("closeModal");
    },
    getRole(data) {
      if (data != null) {
        const roles = {
          customer_admin: 'Admin',
          customer_recruiter: 'Recruiter'
        }
        const {role_type} = data[0];
        return roles[role_type] || '--';
      }
      return "--";
    },
    getLastLogin(data) {
      if (data != null) {
        return moment(data).format("Do MMM YYYY");
      }
      return "--";
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
  },
  mounted() {
    this.fetchFacilityUserReport(this.facility.customer_uid);
    this.darkModal = true;
  }
};
</script>


