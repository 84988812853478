<template>
  <div class="facility-config">
    <CModal
      class="text-black popupfnt imageModel"
      :show.sync="darkModal"
      :close-on-backdrop="false "
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">{{facilityData.facility_name}} Configuration</h6>
        <CButtonClose @click="closeModal" class="text-black" />
      </template>
      <template #footer>
        <CButton type="button" color="primary" class="px-4" @click="closeModal">Close</CButton>
      </template>
      <LoadingOverlay v-if="!getFacilityConfig.length" />
      <div>
        <ValidationObserver ref="addFacilityDetail" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmitStep1)">
            <CCardGroup class="has-loading-overlay">
              <CCard class="p-2">
                <CCardBody>
                  <CRow class="row mb-3">
                    <label class="col-lg-4 col-md-12">Total Max Facility Administrator</label>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <TextInput
                          name="MAX_ADMINS"
                          type="number"
                          :value="facilities.MAX_ADMINS"
                          @change="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                  <CRow class="row mb-3">
                    <label class="col-lg-4 col-md-12">
                      Total Max Facility Users
                      <span class="sm-title">(Recruiters)</span>
                    </label>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <TextInput
                          name="MAX_RECRUITERS"
                          type="number"
                          :value="facilities.MAX_RECRUITERS"
                          @change="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                  <CRow class="row mb-3">
                    <label class="col-lg-4 col-md-12">Limit No. of Candidates for Shortlist</label>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <TextInput
                          name="MAX_SHORTLISTS"
                          type="number"
                          :value="facilities.MAX_SHORTLISTS"
                          @change="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                  <CRow class="row mb-3">
                    <label class="col-lg-4 col-md-12">Limit No. of Candidates for Interview</label>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <TextInput
                          name="MAX_INTERVIEWS"
                          type="number"
                          :value="facilities.MAX_INTERVIEWS"
                          @change="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                  <CRow class="row mb-3">
                    <label class="col-lg-4 col-md-12">Limit No. of Candidates for Recruit</label>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <TextInput
                          name="MAX_RECRUITS"
                          type="number"
                          :value="facilities.MAX_RECRUITS"
                          @change="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                  <CRow class="row mb-3">
                    <label class="col-lg-4 col-md-12">Expiry Days for Shortlist</label>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <TextInput
                          name="SHORTLIST_EXPIRY_TIME"
                          type="number"
                          :value="facilities.SHORTLIST_EXPIRY_TIME"
                          @change="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                  <CRow class="row mb-3">
                    <label class="col-lg-4 col-md-12">Expiry Days for Interview</label>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <TextInput
                          name="INTERVIEW_EXPIRY_TIME"
                          type="number"
                          :value="facilities.INTERVIEW_EXPIRY_TIME"
                          @change="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                  <CRow class="row mb-3">
                    <label class="col-lg-4 col-md-12">Expiry Days for Recruit</label>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <TextInput
                          name="RECRUITED_EXPIRY_TIME"
                          type="number"
                          :value="facilities.RECRUITED_EXPIRY_TIME"
                          @change="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>


<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

import TextInput from "@/components/reusable/Fields/TextInput";

import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });

export default {
  components: {
    TextInput
  },
  props: ["facilityData"],
  data() {
    return {
      darkModal: false,
      payload: {}
    };
  },
  computed: {
    ...mapGetters(["getFacilityConfig"]),
    facilities() {
      if (this.getFacilityConfig.length) {
        return this.getFacilityConfig.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.key]: item.value.replace("d", "") }),
          {}
        );
      }
      return {};
    }
  },
  methods: {
    ...mapActions(["fetchFacilityConfig", "updateFacilityConfig"]),
    closeModal() {
      this.$emit("closeModal");
    },
    handleInput(key, value) {
      Vue.set(this.facilities, key, value);
      if (value) {
        if (
          [
            "RECRUITED_EXPIRY_TIME",
            "INTERVIEW_EXPIRY_TIME",
            "SHORTLIST_EXPIRY_TIME"
          ].includes(key)
        )
          value = "d" + value;
        this.updateFacilityConfig({
          key,
          value,
          customer_id: this.facilityData.customer_uid
        });
      }
    }
  },
  mounted() {
    this.fetchFacilityConfig(this.facilityData.customer_uid);
    this.darkModal = true;
  }
};
</script>